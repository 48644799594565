import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import * as S from './styled';
import routes from '../../../router/pages/routes';
import { useContextInfo } from '../../../context';
import { SettingsIcon } from '../../Icons';

const SmallProfileBar = ({ showTab }) => {
  const navigate = useNavigate();
  const { userInfo } = useContextInfo();
  const { info } = userInfo;

  const [openDropdown, setOpenDropdown] = useState(false);

  const redirectToUserSettings = () => {
    navigate(routes.USER_SETTINGS);
    setOpenDropdown(false);
  };

  const toggleDropdownMenu = () => setOpenDropdown((prev) => !prev);

  return (
    <S.Container showTab={showTab}>
      <S.ImageContainer>
        <AccountCircleIcon />
      </S.ImageContainer>

      <S.ProfileContainer>
        <S.ProfileName>{info?.fullName}</S.ProfileName>
        <S.ProfileEmail>{info?.email}</S.ProfileEmail>
      </S.ProfileContainer>

      <S.ArrowBlock onClick={toggleDropdownMenu}>
        {openDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </S.ArrowBlock>

      {openDropdown && (
        <S.DropDownBlock>
          <S.DropDownMenu>
            <S.DropDownItem onClick={redirectToUserSettings}>
              <SettingsIcon />
              User Settings
            </S.DropDownItem>
          </S.DropDownMenu>
        </S.DropDownBlock>
      )}
    </S.Container>
  );
};

export default SmallProfileBar;
