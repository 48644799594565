import React, { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../services';
import { ROLES_TYPES } from '../enums';

const UserContext = createContext();

export const useContextInfo = () => {
  return useContext(UserContext);
};

export const ContextProvider = ({ children }) => {
  const updateInfo = (info) => {
    setUserInfo((prev) => ({
      ...prev,
      isSiteAdmin: info?.role === ROLES_TYPES.SITE_ADMIN,
      info
    }));
  };

  const [userInfo, setUserInfo] = useState({ info: null, isSiteAdmin: false, updateInfo });
  const [selectedSite, setSelectedSite] = useState(0);

  useEffect(() => {
    if (!userInfo?.info) {
      try {
        api.user.getUserInfo().then(({ data }) => {
          setUserInfo((prev) => ({
            ...prev,
            isSiteAdmin: data?.role === ROLES_TYPES.SITE_ADMIN,
            info: data
          }));
        });
      } catch (e) {
        console.error('error ', e);
      }
    }
  }, []);

  return <UserContext.Provider value={{userInfo, setUserInfo, selectedSite, setSelectedSite}}>{children}</UserContext.Provider>;
};

