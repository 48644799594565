import CommonModalWithHeader from "../../../CommonModalWithHeader";
import {USER_ACTION_TYPES} from "../../../../../enums";
import * as S from "../EditGroup/styled";
import Input from "../../../Input";
import React, {useState} from "react";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {useMutation} from "@apollo/client";
import {CREATE_GROUP, GET_GROUP_BY_ID} from "../../../../../apollo/group";
import {GET_CONNECTOR_BY_ID} from "../../../../../apollo/connector";
import {GET_CHARGING_SESSIONS_BY_SITE} from "../../../../../apollo/chargingHistory";
import {billingTypes} from "../../../../BillingTypes/billingTypes";


const CreateGroup = ({
                         handlerChangeModal,
                         handlerActions,
                         siteValue,
                         connectorValue,
                     }) => {
    const { t } = useTranslation();

    // Mutation f�r das Erstellen einer Gruppe
    const [createGroup] = useMutation(CREATE_GROUP, {
        refetchQueries: [
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            },
            {
                query: GET_GROUP_BY_ID,
                variables: {
                    groupFilter: {
                        site_id: +siteValue?.id
                    }
                }
            },
            {
                query: GET_CHARGING_SESSIONS_BY_SITE,
                variables: {
                    siteFilter: {
                        id: siteValue?.id
                    }
                }
            },
        ]
    })

    // Validierungsschema
    const validationSchema = Yup.object({
        groupName: Yup.string().required(t("auth.name_required")),
        billingMethod: Yup.string().required(t("auth.billing_method_required")),
    });

    // Formik-Konfiguration
    const formik = useFormik({
        initialValues: {
            groupName: "",
            billingMethod: "none",
            site: siteValue?.name,
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            await createGroup({
                variables: {
                    group: {
                        group_name: values.groupName,
                        billing_method: values.billingMethod,
                        site_id: siteValue?.id,
                    },
                },
            });
            handlerActions(t("modal.group_create_success"));
            handlerChangeModal(USER_ACTION_TYPES.ASSIGNED);
        },
    });

    return (
        <CommonModalWithHeader
            title={t('modal.edit_group')}
            subtitle={t('modal.edit_group_explain_text')}
            buttonText={t('modal.submit')}
            onClose={() => handlerChangeModal(USER_ACTION_TYPES.ASSIGNED)}
            buttonFunc={formik.handleSubmit}>
            <S.Form>
                <Input
                    label={t('auth.group_name')}
                    id="groupName"
                    name="groupName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.groupName}
                    onBlur={formik.handleBlur}
                    error={formik.touched.groupName && Boolean(formik.errors.groupName)}
                    helperText={formik.touched.groupName && formik.errors.groupName}
                />

                <S.SelectStyle
                    options={billingTypes()}
                    onChange={(e) =>
                        formik.setFieldValue("billingMethod", e.target.value)
                    }
                    value={formik.values.billingMethod}
                    label={t("auth.billing_method")}
                />

                <Input
                    label={t('auth.site')}
                    id="site"
                    name="site"
                    type="text"
                    disabled={true}
                    value={formik.values.site || siteValue?.name}
                />

            </S.Form>
        </CommonModalWithHeader>
    );
};

export default CreateGroup