import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import { CircularProgress, Zoom } from '@mui/material';

import * as S from './styled';
import {
  ArrowBack,
  BatteryAnimation,
  CopyIcon,
  ErrorIcon,
  PlayIcon,
  ReadyIcon,
  StopIcon
} from '../../components/Icons';
import BatteryPreparingAnimated from '../../components/AnimatedIcons/battery-preparing-animated';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { OfflineIcon } from '../../components/Icons/OfflineIcon';
import CommonModal from '../../components/UI/CommonModal';
import AvailableUsers from '../../components/UI/AvailableUsers';
import WarningModal from '../../components/UI/WarningModal';
import bg from '../../assets/img/cardItemImg.png';
import station from '../../assets/img/stationImg.png';
import ActionsWithUser from '../../components/UI/ActionsWithUser';
import { GET_CONTRACT } from '../../apollo/chargingHistory';
import EditTitleModal from '../../components/UI/EditTitleModal';
import ChartForCard from '../../components/UI/ChartForCard/ChartForCard';
import { GET_SITE_INFO } from '../../apollo/site';
import { ALERT_TYPES, CHARGING_STATUS_TYPES } from '../../enums';
import ErrorModal from '../../components/UI/ErrorModal';
import { useContextInfo } from '../../context';
import AlertMessage from '../../components/UI/AlertMessage';
import { GET_USERS_CONTRACT } from '../../apollo/contracts';
import {charge_amount_formating, cost_formating} from "../../utils/custom_formating";
import { START_CHARGING, STOP_CHARGING, UNLOCK_CONNECTOR } from '../../apollo/connector';
import { LockOpen } from '@mui/icons-material';
import OccupiedByBlock from '../../components/UI/OccupiedByBlock';

const AdaptiveCardItem = () => {
  const { id, siteID } = useParams();
  const { t } = useTranslation();
  const { userInfo } = useContextInfo();
  const { isSiteAdmin, info } = userInfo;
  const navigate = useNavigate();

  const { data, refetch } = useQuery(GET_CONTRACT, {
    variables: {
      filter: {
        evseId: id,
        onlyNewestChargingSession: true
      }
    },
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const { data: siteData, loading } = useQuery(GET_SITE_INFO, {
    variables: {
      filter: {
        id: +siteID
      }
    }
  });

  const [updateConnectorStatus, { loading: updateLoading }] = useLazyQuery(GET_CONTRACT, {
    variables: {
      filter: {
        evseId: id
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: contract } = useQuery(GET_USERS_CONTRACT, {
    skip: isSiteAdmin && siteID === '',
    variables: {
      userFilter: {
        userId: info?.id,
        siteId: +siteID
      }
    }
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isOpenActionsModal, setIsOpenActionsModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(ALERT_TYPES.ERROR);

  const [startCharging, { data: start_response, loading: start_loading, error: start_error }] = useMutation(START_CHARGING);
  const [stopCharging, { data: stop_response, loading: stop_loading, error: stop_error }] = useMutation(STOP_CHARGING);
  const [unlockConnector, { data: unlock_response, loading: unlock_loading, error: unlock_error }] = useMutation(UNLOCK_CONNECTOR);

  const statusInfo = data?.connector?.connectorStates?.[0];
  const newestChargingSession = data?.connector?.chargingSessions?.[0];
  const chargingInfo = statusInfo && JSON.parse(statusInfo?.message);

  const startChargingProcess = async (user = null) => {
    //const params = { evse_id: id, evco_id: user?.evcoId };

    const variables = {
      contractConnector: {
        evcoId: user?.evcoId,
        evseId: id
      }
    }

    if (!isSiteAdmin) {
      //params.evco_id = contract?.users?.[0]?.contracts?.[0]?.evcoId;
      variables.contractConnector.evcoId = contract?.users?.[0]?.contracts?.[0]?.evcoId;
    }

    setIsWaiting(true);

    try {
      //await api.charging.startCharging(params);
      await startCharging({variables})
      if (start_error != null){
        setOpenSnackbar(true);
        const errorMessage = start_error?.message;
        setErrorText(errorMessage);
      } else {
        setTimeout(() => {
          updateConnectorStatus();
          setIsWaiting(false);
        }, 2000);
      }
    } catch (e) {
      setOpenSnackbar(true);
      setTimeout(() => {
        setIsWaiting(false);
      }, 5000);
      const errorMessage =
        e?.response?.data?.detail?.[0] || e?.response?.data?.status
          ? `[ERROR]: ${e?.response?.data?.detail?.[0] || e?.response?.data?.status}`
          : e?.message;
      setErrorText(errorMessage);
    }
  };

  const handlerChooseUser = async (user = null) => {
    await startChargingProcess(user);
    closeModal();
  };

  const handlerStartProcess = () => {
    if (isSiteAdmin) {
      setIsOpenModal(true);
    } else {
      handlerChooseUser();
    }
  };

  const stopChargingProcess = async () => {
    //const params = { evse_id: id, transaction_id: 0 };

    const variables = {
      connectorTransaction: {
        evseId: id,
        transactionId: 0
      }
    }

    setIsWaiting(true);

    try {
      //await api.charging.stopCharging(params);
      await stopCharging({variables})
      if (stop_error != null){
        setOpenSnackbar(true);
        const errorMessage = start_error?.message;
        setErrorText(errorMessage);
      } else {
        setTimeout(() => {
          updateConnectorStatus();
          setIsWaiting(false);
        }, 5000);
      }

    } catch (e) {
      setOpenSnackbar(true);
      const errorMessage =
        e?.response?.data?.detail?.[0] || e?.response?.data?.status
          ? `[ERROR]: ${e?.response?.data?.detail?.[0] || e?.response?.data?.status}`
          : e?.message;
      setErrorText(errorMessage);
      setTimeout(() => {
        setIsWaiting(false);
      }, 5000);
    }
  };

  const unlockConnectorHandler = async (evseId) => {
    const variables = {
      connectorFilter: {
        evseId: evseId
      }
    }

    try {
      await unlockConnector({variables})
      if (start_error != null){
        setOpenSnackbar(true);
        const errorMessage = start_error?.message;
        setErrorText(errorMessage);
      } else {
        setOpenSnackbar(true);
        //console.log(unlock_response?.unlockConnector)
        setAlertSeverity(ALERT_TYPES.SUCCESS);
        setErrorText("unlock request successful send");
        setTimeout(() => {
          updateConnectorStatus();
        }, 500);
      }
    } catch (e) {
      setOpenSnackbar(true);
      const errorMessage =
        e?.response?.data?.detail?.[0] || e?.response?.data?.status
          ? `[ERROR]: ${e?.response?.data?.detail?.[0] || e?.response?.data?.status}`
          : e?.message;
      setErrorText(errorMessage);
    }

  };

  const closeModal = () => setIsOpenModal(false);

  const closeActionsModal = () => setIsOpenActionsModal(false);

  const closeWarningModal = () => setIsOpenWarningModal(false);

  const handlerStopProcess = async () => {
    await stopChargingProcess();
    closeWarningModal();
  };

  const showErrorModal = () => setOpenErrorModal(true);

  const closeErrorModal = () => setOpenErrorModal(false);

  const setStatusWaiting = useMemo(() => {
    return (
      <S.StatusBlock>
        <CircularProgress />
        <S.StatusText>{t('overviewWallboxes.waiting')}</S.StatusText>
      </S.StatusBlock>
    );
  }, [isWaiting]);

  const setStatus = useMemo(() => {
    if (statusInfo?.state) {
      switch (statusInfo?.state) {
        case CHARGING_STATUS_TYPES.READY: {
          return (
            <>
              <S.StatusBlock>
                <ReadyIcon />
                <S.StatusText>{t('overviewWallboxes.status_ready')}</S.StatusText>
              </S.StatusBlock>

              <S.ActionBlock onClick={handlerStartProcess}>
                <PlayIcon />
              </S.ActionBlock>
            </>
          );
        }

        case CHARGING_STATUS_TYPES.SUSPENDED:
        case CHARGING_STATUS_TYPES.SUSPENDED_ENV: {
          if(newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin){
            return (
                <>
                  <S.StatusBlockOccupied>
                    <BatteryPreparingAnimated />
                    <S.StatusText>{t('overviewWallboxes.occupied')}</S.StatusText>
                  </S.StatusBlockOccupied>
                </>
            );
          } else {
            return (
                <>
                  <S.StatusBlock>
                    <BatteryPreparingAnimated />
                    <S.StatusText>{t('overviewWallboxes.suspended')}</S.StatusText>
                  </S.StatusBlock>

                  <S.ActionBlock onClick={handlerStartProcess}>
                    <PlayIcon />
                  </S.ActionBlock>
                </>
            );
          }

        }

        case CHARGING_STATUS_TYPES.PREPARING: {
          return (
            <>
              <S.StatusBlock>
                <BatteryPreparingAnimated />
                <S.StatusText>{t('overviewWallboxes.preparing')}</S.StatusText>
              </S.StatusBlock>

              <S.ActionBlock onClick={handlerStartProcess}>
                <PlayIcon />
              </S.ActionBlock>
            </>
          );
        }

        case CHARGING_STATUS_TYPES.CHARGING: {
          if(newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin){
            return (
                <>
                  <S.StatusBlockOccupied>
                    <BatteryAnimation />
                    <S.StatusText>{t('overviewWallboxes.occupied')}</S.StatusText>
                  </S.StatusBlockOccupied>
                </>
            );
          } else {
            return (
                <>
                  <S.StatusBlock>
                    <BatteryAnimation />
                    <S.StatusText>{t('overviewWallboxes.charging')}</S.StatusText>
                  </S.StatusBlock>

                  <S.ActionBlock charging={true} onClick={() => setIsOpenWarningModal(true)}>
                    <StopIcon />
                  </S.ActionBlock>
                </>
            );
          }

        }

        case CHARGING_STATUS_TYPES.ERROR: {
          return (
            <>
              <S.StatusBlock>
                <ErrorIcon />
                <S.StatusText>{t('overviewWallboxes.error')}</S.StatusText>
              </S.StatusBlock>

              <S.ActionBlock charging={true} onClick={showErrorModal}>
                <PriorityHighIcon style={{ fill: '#FB3E3F', width: '100%', height: '50px' }} />
              </S.ActionBlock>
            </>
          );
        }

        case CHARGING_STATUS_TYPES.OFFLINE: {
          return (
            <>
              S.
              <S.StatusBlock>
                <OfflineIcon />
                <S.StatusText>{t('overviewWallboxes.offline')}</S.StatusText>
                <S.OfflineLastDateText>
                  {JSON.parse(statusInfo?.message)?.msg}
                </S.OfflineLastDateText>
              </S.StatusBlock>
            </>
          );
        }

        case CHARGING_STATUS_TYPES.NO_CONNECTION:
        case CHARGING_STATUS_TYPES.UNAVAILABLE: {
          return (
            <>
              S.
              <S.StatusBlock>
                <OfflineIcon />
                <S.StatusText>{t('overviewWallboxes.no_connection')}</S.StatusText>
                <S.OfflineLastDateText>
                  {JSON.parse(statusInfo?.message)?.msg}
                </S.OfflineLastDateText>
              </S.StatusBlock>
            </>
          );
        }

        default:
          return (
            <>
              <S.StatusBlock>
                <ErrorIcon />
                <S.StatusText>{t('overviewWallboxes.error')}</S.StatusText>
              </S.StatusBlock>

              <S.ActionBlock charging={true} onClick={showErrorModal}>
                <PriorityHighIcon style={{ fill: '#FB3E3F', width: '100%', height: '50px' }} />
              </S.ActionBlock>
            </>
          );
      }
    }
  }, [statusInfo]);

  if (loading) {
    return <CircularProgress />;
  }

  const handlerOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handlerCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
    // reset Alert severity
    setAlertSeverity(ALERT_TYPES.ERROR);
  }

  return (
    <Zoom in={true} style={{ transitionDelay: '200ms' }}>
      <S.CardWrapper>
        <AlertMessage severity={alertSeverity} open={openSnackbar} onClose={closeSnackbar} errorText={errorText} />

        <ErrorModal
          open={openErrorModal}
          onClose={closeErrorModal}
          title={data?.connector?.connectorStates?.[0]?.message}
        />

        <CommonModal open={isOpenModal} onClose={closeModal}>
          <AvailableUsers
            onClose={closeModal}
            handlerChooseUser={handlerChooseUser}
            title={data?.connector?.name}
            users={data?.connector?.contracts}
            evseId={data?.connector?.evseId}
          />
        </CommonModal>

        <CommonModal open={isOpenActionsModal} onClose={closeActionsModal}>
          <ActionsWithUser
            cardPage={true}
            handlerCloseModal={closeActionsModal}
            connectorValue={{ evseId: id }}
            siteValue={siteData?.site}
            handlerRefetchContract={refetch}
            activeUsers={data?.connector?.contracts}
            activeGroups={data?.connector?.groups}
          />
        </CommonModal>

        <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
          <WarningModal
            onClose={closeWarningModal}
            handlerProcessedClick={handlerStopProcess}
            description={''}
          />
        </CommonModal>

        <CommonModal open={isOpenEditModal} onClose={handlerCloseEditModal}>
          <EditTitleModal
            onClose={handlerCloseEditModal}
            value={data?.connector}
            adaptivePage={true}
          />
        </CommonModal>

        <S.ImageBlock
          image={bg}
          error={
            statusInfo?.state === CHARGING_STATUS_TYPES.ERROR ||
            statusInfo?.state === CHARGING_STATUS_TYPES.OFFLINE
          }>
          <S.ArrowBackBlock onClick={() => navigate('/', { state: `${siteID}` })}>
            <ArrowBack />
          </S.ArrowBackBlock>
          <S.StationImg src={station} alt="station" />
          {isWaiting || updateLoading ? setStatusWaiting : setStatus}
        </S.ImageBlock>

        <S.InfoBlock>

        <S.InfoHeader>
            <div>
              <S.InfoHeaderTitle>
                {data?.connector?.name}
                {isSiteAdmin && (
                  <CopyIcon style={{ marginLeft: '8px' }} fontSize="small" width={'16px'} height={'16px'} onClick={handlerOpenEditModal} />
                )}
              </S.InfoHeaderTitle>
              <S.InfoHeaderSubtitle>
                {data?.connector?.evseId}
                {isSiteAdmin && (
                  <LockOpen style={{ verticalAlign: 'bottom', marginLeft: '8px' }} fontSize="small" onClick={() => unlockConnectorHandler(data?.connector?.evseId)} />
                )}
              </S.InfoHeaderSubtitle>
            </div>
            <div>                   
              <OccupiedByBlock
                isSiteAdmin={isSiteAdmin}
                state={statusInfo?.state}
                newestChargingSession={newestChargingSession}
              />          
            </div>

           
          </S.InfoHeader>

          

          {statusInfo?.state === CHARGING_STATUS_TYPES.PREPARING ||
          (statusInfo?.state === CHARGING_STATUS_TYPES.CHARGING && (isSiteAdmin || newestChargingSession.user_id === info.id)) ? (
            <>
              <S.Content>
                <S.ContentItem>
                  <S.ContentItemTitle>{t('overviewWallboxes.start')}</S.ContentItemTitle>
                  <S.ContentItemData>
                    {chargingInfo?.starttime
                      ? moment(chargingInfo?.starttime).format('YYYY-MM-DD HH:mm')
                      : '-'}
                  </S.ContentItemData>
                </S.ContentItem>

                <S.ContentBorder />

                <S.ContentItem>
                  <S.ContentItemTitle>{t('overviewWallboxes.duration')}</S.ContentItemTitle>
                  <S.ContentItemData>
                    {chargingInfo?.duration
                      ? moment.utc(chargingInfo?.duration * 1000).format('HH:mm:ss')
                      : '00:00:00'}
                  </S.ContentItemData>
                </S.ContentItem>
              </S.Content>

              <S.Content>
                <S.ContentItem>
                  <S.ContentItemTitle>
                    {t('overviewWallboxes.power_consumption')}
                  </S.ContentItemTitle>
                  <S.ContentItemData>
                    {chargingInfo?.amount ? charge_amount_formating(chargingInfo?.amount) : 0} kWh
                  </S.ContentItemData>
                </S.ContentItem>

                <S.ContentBorder />

                <S.ContentItem>
                  <S.ContentItemTitle>{t('overviewWallboxes.costs')}</S.ContentItemTitle>
                  <S.ContentItemData>€{chargingInfo?.cost ? cost_formating(chargingInfo?.cost) : 0}</S.ContentItemData>
                </S.ContentItem>
              </S.Content>
            </>
          ) : (statusInfo?.state === CHARGING_STATUS_TYPES.SUSPENDED ||
            statusInfo?.state === CHARGING_STATUS_TYPES.SUSPENDED_ENV) && (isSiteAdmin || newestChargingSession.user_id === info.id) ? (<>
            <S.Content>
              <S.ContentItem>
                <S.ContentItemTitle>{t('overviewWallboxes.start')}</S.ContentItemTitle>
                <S.ContentItemData>
                  {chargingInfo?.last_charging_details?.starttime
                    ? moment(chargingInfo?.last_charging_details?.starttime).format('YYYY-MM-DD HH:mm')
                    : '-'}
                </S.ContentItemData>
              </S.ContentItem>

              <S.ContentBorder />

              <S.ContentItem>
                <S.ContentItemTitle>{t('overviewWallboxes.duration')}</S.ContentItemTitle>
                <S.ContentItemData>
                  {chargingInfo?.last_charging_details?.duration
                    ? moment.utc(chargingInfo?.last_charging_details?.duration * 1000).format('HH:mm:ss')
                    : '00:00:00'}
                </S.ContentItemData>
              </S.ContentItem>
            </S.Content>

            <S.Content>
              <S.ContentItem>
                <S.ContentItemTitle>
                  {t('overviewWallboxes.power_consumption')}
                </S.ContentItemTitle>
                <S.ContentItemData>
                  {chargingInfo?.last_charging_details?.amount ? charge_amount_formating(chargingInfo?.last_charging_details?.amount) : 0} kWh
                </S.ContentItemData>
              </S.ContentItem>

              <S.ContentBorder />

              <S.ContentItem>
                <S.ContentItemTitle>{t('overviewWallboxes.costs')}</S.ContentItemTitle>
                <S.ContentItemData>€{chargingInfo?.last_charging_details?.cost ? cost_formating(chargingInfo?.last_charging_details?.cost) : 0}</S.ContentItemData>
              </S.ContentItem>
            </S.Content>
          </>) : (
            <ChartForCard evseId={id} />
          )}

          {isSiteAdmin && (
            <>
              <S.UserBlockTitle>{t('overviewWallboxes.manage_users')}</S.UserBlockTitle>
              <S.UserBlock>
                {data?.connector?.groups?.length
                  ? data?.connector?.groups?.slice().sort((a, b) => a.group_name.localeCompare(b.group_name)).map((item) => (
                    <S.GroupItem key={item?.group_id}>{item?.group_name}</S.GroupItem>
                  ))
                  : null}
                {data?.connector?.contracts?.length
                  ? data?.connector?.contracts?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                    <S.UserItem key={item?.evcoId}>{item?.name}</S.UserItem>
                  ))
                  : null}
                <S.UserCopyIcon>
                  <CopyIcon width={14} height={14} onClick={() => setIsOpenActionsModal(true)} />
                </S.UserCopyIcon>
              </S.UserBlock>
            </>
          )}
        </S.InfoBlock>
      </S.CardWrapper>
    </Zoom>
  );
};

export default AdaptiveCardItem;
