import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import * as S from './styled';
import img from '../../../../assets/icons/powerstation-adaptive.svg';
import { BatteryAnimation, ErrorIcon, ReadyIcon } from '../../../Icons';
import BatteryPreparingAnimated from '../../../AnimatedIcons/battery-preparing-animated';
import { OfflineIcon } from '../../../Icons/OfflineIcon';
import { GET_CONNECTOR_BY_ID } from '../../../../apollo/connector';
import { CircularProgress } from '@mui/material';
import { CHARGING_STATUS_TYPES } from '../../../../enums';
import { useTranslation } from 'react-i18next';
import {useContextInfo} from "../../../../context";

const ListViewAdaptive = ({ value, siteValue, latestChargingSession, get_session_loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = useContextInfo();
  const { isSiteAdmin, info } = userInfo;

  const { data, loading } = useQuery(GET_CONNECTOR_BY_ID, {
    variables: {
      filter: {
        evseId: value?.evseId
      }
    }
  });

  const statusInfo = value?.connectorStates?.[0];
  const newestChargingSession = latestChargingSession?.charging_latest_session_for_evse_id;

  const renderStatusBlock = useMemo(() => {
    switch (statusInfo?.state) {
      case CHARGING_STATUS_TYPES.READY: {
        return (
          <S.StatusBlock>
            <ReadyIcon />
            <S.StatusBlockText>{t('overviewWallboxes.status_ready')}</S.StatusBlockText>
          </S.StatusBlock>
        );
      }

      case CHARGING_STATUS_TYPES.SUSPENDED:
      case CHARGING_STATUS_TYPES.SUSPENDED_ENV: {
          if(newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin){
              return (
                  <S.StatusBlockOccupied orange="true">
                      <BatteryAnimation />
                      <S.StatusBlockText>{t('overviewWallboxes.occupied')}</S.StatusBlockText>
                  </S.StatusBlockOccupied>
              );
          } else {
              return (
                  <S.StatusBlock orange="true">
                      <BatteryPreparingAnimated />
                      <S.StatusBlockText>{t('overviewWallboxes.suspended')}</S.StatusBlockText>
                  </S.StatusBlock>
              );
          }
      }

      case CHARGING_STATUS_TYPES.PREPARING: {
        return (
          <S.StatusBlock orange="true">
            <BatteryPreparingAnimated />
            <S.StatusBlockText>{t('overviewWallboxes.preparing')}</S.StatusBlockText>
          </S.StatusBlock>
        );
      }

      case CHARGING_STATUS_TYPES.CHARGING: {
          if(newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin){
              return (
                  <S.StatusBlockOccupied blue="true">
                      <BatteryAnimation />
                      <S.StatusBlockText>{t('overviewWallboxes.occupied')}</S.StatusBlockText>
                  </S.StatusBlockOccupied>
              );
          } else {
              return (
                  <S.StatusBlock blue="true">
                      <BatteryAnimation />
                      <S.StatusBlockText>{t('overviewWallboxes.charging')}</S.StatusBlockText>
                  </S.StatusBlock>
              );
          }

      }

      case CHARGING_STATUS_TYPES.OFFLINE: {
        return (
          <S.OfflineBlock>
            <OfflineIcon />
            <S.OfflineBlockText>{t('overviewWallboxes.offline')}</S.OfflineBlockText>
          </S.OfflineBlock>
        );
      }

      case CHARGING_STATUS_TYPES.ERROR: {
        return (
          <S.OfflineBlock error="true">
            <ErrorIcon />
            <S.OfflineBlockText>{t('overviewWallboxes.error')}</S.OfflineBlockText>
          </S.OfflineBlock>
        );
      }

      case CHARGING_STATUS_TYPES.NO_CONNECTION:
      case CHARGING_STATUS_TYPES.UNAVAILABLE: {
        return (
          <S.OfflineBlock>
            <OfflineIcon />
            <S.OfflineBlockText>{t('overviewWallboxes.no_connection')}</S.OfflineBlockText>
          </S.OfflineBlock>
        );
      }

      default: {
        return (
          <S.OfflineBlock error="true">
            <ErrorIcon />
            <S.OfflineBlockText>{t('overviewWallboxes.error')}</S.OfflineBlockText>
          </S.OfflineBlock>
        );
      }
    }
  }, [statusInfo, get_session_loading]);

  const redirectToCardPage = () => {
    navigate(`/connector/${value?.evseId}/${siteValue?.id}`);
  };

  if (loading || get_session_loading) {
    return <CircularProgress />;
  }

  return (
    <S.Wrapper onClick={redirectToCardPage}>
      <S.Image src={img} />
      <S.ListTitle>{data?.connector?.name}</S.ListTitle>
      {renderStatusBlock}
    </S.Wrapper>
  );
};

export default ListViewAdaptive;
