import styled from 'styled-components';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

const StyledSelectSite = styled(Select)`
  margin-bottom: 14px;
  background: #fff;
  border-radius: 8px;

  //padding: 0;
  ////max-width: 340px;
  //width: 100%;
  //border-radius: 8px;
  //box-shadow: 0 2px 10px rgba(51, 51, 51, 0.1);
  //margin-top: 14px;
  //& .MuiInputBase-input {
  //  width: 100%;
  //  border-radius: 12px;
  //  font-weight: 500;
  //}
  //
  //&
  //  .css-xxs3io-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  //  .MuiOutlinedInput-notchedOutline {
  //  border-color: gray;
  //}
  //
  //.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  //  border-color: transparent;
  //}

  // @media (${({ theme }) => theme.base.breakpoint.XL}) {
  //   width: 100%;
  //   max-width: 100%;
  // }
`;

const FormControlBlock = styled(FormControl)`
  width: 100%;
`;

export { StyledSelectSite, FormControlBlock };
