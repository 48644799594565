import styled from 'styled-components';
import Modal from '@mui/material/Modal';

const ModalWrapper = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center; /* flex-start, wenn das Modal oben statt zentriert positioniert werden soll */
  overflow: auto; /* erlaube scrollen */

  & > .MuiBackdrop-root {
    opacity: 0.9 !important;
    background: #d9d9d9;
  }

  & > div {
    outline: none;
    overflow-y: auto; /* Erlaube vertikales Scrollen innerhalb des Modals */
  }
`;

export { ModalWrapper };
