import React, { useState } from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';

import { CloseIcon } from '../../Icons';
import * as S from './styled';
import CommonModal from '../CommonModal';
import WarningModal from '../WarningModal';
import { GET_STATUS_FOR_CONNECTOR } from '../../../apollo/chargingHistory';
import { CLEAR_CACHE, RESET_CHARGEPOINT } from '../../../apollo/connector';
import { useTranslation } from 'react-i18next';
import { ALERT_TYPES } from '../../../enums';
import { useContextInfo } from '../../../context';

const ActionsBlock = ({
  title,
  onClose,
  chargepointID,
  siteId,
  showError,
  handlerActionWithChargepoint,
  setAlertSeverity
}) => {
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [resetType, setResetType] = useState('');
  const { t } = useTranslation();
  const { userInfo } = useContextInfo();
  const { isSiteAdmin } = userInfo;

  const [updateConnectorStatus] = useLazyQuery(GET_STATUS_FOR_CONNECTOR, {
    skip: siteId === '',
    variables: {
      chargepointFilter: {
        siteId
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [resetChargepoint, { data: reset_response, loading: reset_loading, error: reset_error }] = useMutation(RESET_CHARGEPOINT);
  const [clearCache, { data: clear_response, loading: clear_loading, error: clear_error }] = useMutation(CLEAR_CACHE);

  const handlerOpenWarningModal = (type) => {
    setResetType(type);
    setOpenWarningModal(true);
  };

  const handleClearCache = async () => {
    const variables = {
      chargepointFilter: {
        id: chargepointID,
      }
    }

    handlerActionWithChargepoint(true);

    try {
      await clearCache({variables})
      setAlertSeverity(ALERT_TYPES.SUCCESS)
      showError("Unlock Request sent")
      setTimeout(() => {
        updateConnectorStatus();
        handlerActionWithChargepoint(false);
        }, 500);
    } catch (e) {
      showError(e.message);
      setTimeout(() => {
        handlerActionWithChargepoint(false);
      }, 500);
    } finally {
      onClose();
    }

  }

  const handleReset = async () => {
    const params = {
      chargepoint_id: chargepointID,
      type: resetType
    };

    const variables = {
        chargepointResetFilter: {
          id: chargepointID,
          type: resetType
        }
    }

    handlerActionWithChargepoint(true);

    try {
      //await api.charging.resetChargepoint(params);
      await resetChargepoint({variables})
      if (reset_error != null){
        const errorMessage = reset_error?.message;
        showError(errorMessage);
      } else {
        setTimeout(() => {
          updateConnectorStatus();
          handlerActionWithChargepoint(false);
        }, 5000);
      }
    } catch (e) {
      showError(e.message);
      setTimeout(() => {
        handlerActionWithChargepoint(false);
      }, 500);
    } finally {
      onClose();
    }
  };

  const handlerCloseWarningModal = () => {
    setOpenWarningModal(false);
  };

  return (
    <S.ActionsWrapper>
      <CommonModal open={openWarningModal} onClose={handlerCloseWarningModal}>
        <WarningModal
          onClose={handlerCloseWarningModal}
          handlerProcessedClick={handleReset}
          description={''}
        />
      </CommonModal>
      <S.ActionsHeader>
        <p>{title}</p>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </S.ActionsHeader>

      <S.ActionsButtons>
        <S.ButtonOption text={t('modal.reset_soft')} onClick={() => handlerOpenWarningModal('Soft')} />
        <S.ButtonOption
          orange="true"
          text={t('modal.reset_hard')}
          onClick={() => handlerOpenWarningModal('Hard')}
        />
        {isSiteAdmin && <S.ButtonOption text={t('modal.clear_cache')} onClick={() => handleClearCache()} />}
      </S.ActionsButtons>
    </S.ActionsWrapper>
  );
};

export default ActionsBlock;
