import styled from 'styled-components';

const UserItem = styled.div`
  padding: 5px 18px;
  margin-right: 14px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
  margin-bottom: 6px;
`;

export {UserItem}