export const sortByField = (field) => {
  return (a, b) => (a[field] > b[field] ? 1 : -1);
};

export const sortByTwoFields = (field1, field2) => {
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
  };

  return (a, b) => {
    const value1A = getNestedValue(a, field1);
    const value1B = getNestedValue(b, field1);

    if (value1A > value1B) return 1;
    if (value1A < value1B) return -1;

    const value2A = getNestedValue(a, field2);
    const value2B = getNestedValue(b, field2);

    if (value2A > value2B) return 1;
    if (value2A < value2B) return -1;

    return 0; // Beide Felder sind gleich
  };
};

export function sortByKey(array, key) {
  return array.sort(function(a, b) {
    console.log(a)
    console.log(b)
    const x = a[key]; const y = b[key];
    return x.localeCompare(y);
  });
}