import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';

import * as S from './styled';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import Input from '../../../Input';
import { CopyIcon } from '../../../../Icons';
import { EDIT_CONTRACT, GET_CONTRACT_BY_ID } from '../../../../../apollo/contracts';
import { GET_TOKENS, UNASSIGN_TOKEN } from '../../../../../apollo/token';
import CommonModal from '../../../CommonModal';
import WarningModal from '../../../WarningModal';
import { GET_CONNECTOR_BY_ID } from '../../../../../apollo/connector';
import { ALERT_TYPES, USER_ACTION_TYPES } from '../../../../../enums';
import {ASSIGN_CONTRACT_TO_GROUP, GET_GROUP_BY_ID} from "../../../../../apollo/group";
import CancelIcon from '@mui/icons-material/Cancel';

const EditUser = ({
  user,
  handlerChangeModal,
  handlerEditToken,
  handlerCreateToken,
  connectorValue,
  handlerActions,
  siteValue,
  availableGroups,
  handleUnassignedToken
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log("EditUser");
    tokenRefetch().then(
      () => {
        console.log("refetch Tokens")
      }
    )
  }, []);

  const [assignContractToGroup] = useMutation(ASSIGN_CONTRACT_TO_GROUP,{
    refetchQueries: [
      {
        query: GET_GROUP_BY_ID,
        variables: {
          groupFilter: {
            site_id: +siteValue?.id
          }
        }
      },
      {
        query: GET_CONTRACT_BY_ID,
        variables: {
          contractFilter: {
            siteId: +siteValue?.id
          }
        }
      },
      {
        query: GET_CONNECTOR_BY_ID,
        variables: {
          filter: {
            evseId: connectorValue?.evseId
          }
        }
      }
    ]
  })

  const [editContract, { error }] = useMutation(EDIT_CONTRACT, {
    refetchQueries: [
      {
        query: GET_GROUP_BY_ID,
        variables: {
          groupFilter: {
            site_id: +siteValue?.id
          }
        }
      },
      {
        query: GET_CONTRACT_BY_ID,
        variables: {
          contractFilter: {
            siteId: +siteValue?.id
          }
        }
      },
      {
        query: GET_CONNECTOR_BY_ID,
        variables: {
          filter: {
            evseId: connectorValue?.evseId
          }
        }
      }
    ]
  });

  const [unassignToken, { error: unassignError, loading: unassignLoading }] = useMutation(
    UNASSIGN_TOKEN,
    {
      refetchQueries: [
        {
          query: GET_TOKENS,
          variables: {
            tokenFilter: {
              id: null,
              evcoId: user?.evcoId,
              siteId: siteValue?.id
            }
          }
        }
      ],
      awaitRefetchQueries: true,
    }
  );

  const {
    data: tokenData,
    loading,
    error: tokenError,
    refetch: tokenRefetch,
  } = useQuery(GET_TOKENS, {
    variables: {
      tokenFilter: {
        id: null,
        evcoId: user?.evcoId,
        siteId: siteValue?.id
      }
    },
    refetchPolicy: 'network-only',
  });

  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [unassignedToken, setUnassignedToken] = useState(null);
  const [groupOptions, setGroupOptions] = useState(null);
  const [selectedGroup, setSelectGroup] = useState('');
  const [originalGroup, setOriginalGroup] = useState('');
  //const [tokens, setTokens] = useState([]);

  /*useEffect(()=>{
    setTokens(tokenData);
  }, tokenData)*/

  useEffect(() => {

    // get group option and which group the user belongs to
    // ADHOC group will be ignored for the options
    const groups_available = availableGroups?.filter((x) => x.group_name !== "ADHOC" ).map(
        (group) => {
          if(group.contracts){
            group.contracts.map(
                (contract) => {

                  if(contract.evcoId === user?.evcoId ){
                    console.log("group selected "+group.group_name)
                    if(originalGroup === ""){
                      setOriginalGroup(group.group_id)
                    }
                    setSelectGroup(group.group_id)
                  }

                }
            )
          }

          // wenn der "edit user" fenster von der "add user" fenster aufgerufen wird die group id direkt aus der user object aus gelesen
          if(user && user.groupId){
            if(originalGroup === ""){
              setOriginalGroup(group.group_id)
            }
            setSelectGroup(group.group_id)
          }

          return {
            id: group.group_id,
            value: group.group_id,
            name: group.group_name
          }
        }
    )

    setGroupOptions(groups_available)
  },[availableGroups])

  const handlerOpenWarningModal = (token) => {
    setIsOpenWarningModal(true);
    setUnassignedToken(token);
  };

  const closeWarningModal = () => {
    setIsOpenWarningModal(false);
    setUnassignedToken(null);
  };

  const handlerUnassignToken = async () => {
    const variables = {
      filter: {
        id: unassignedToken?.id,
        siteId: siteValue?.id,
      }
    };

    await unassignToken({ variables });
    closeWarningModal();

    handlerActions(t('modal.unassign_token_success'));
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required(t('auth.name_required'))
  });

  const submitForm = async (value) => {
    const variables = {
      contract: {
        name: value?.fullName
      },
      filter: {
        evcoId: user?.evcoId
      }
    };

    const groupVariables = {
      assignFilter: {
        evco_id: user?.evcoId,
        group_id: selectedGroup
      }
    };

    await editContract({
      variables
    });

    if(originalGroup !== selectedGroup ){
     await assignContractToGroup({variables: groupVariables})
    }

    handlerActions(t('modal.user_edit_success'));
    handlerChangeModal(USER_ACTION_TYPES.ASSIGNED);
  };

  const handlerSelectGroup = (e) => {
    /*const variables = {
      assignFilter: {
        evco_id: ,
        group_id: selectedGroup
      }
    }*/
    //console.log(e.target.value)
    //console.log(groupOptions)
    const currentValue = groupOptions?.find((item) => item.value === e.target.value);
    //console.log(currentValue)
    setSelectGroup(currentValue?.value);

  }

  const formik = useFormik({
    initialValues: { fullName: user?.name, email: user?.email || user?.user?.email },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  if (error) {
    handlerActions(error.message, ALERT_TYPES.ERROR);
  } else if (unassignError) {
    handlerActions(unassignError.message, ALERT_TYPES.ERROR);
  } else if (tokenError) {
    handlerActions(tokenError.message, ALERT_TYPES.ERROR);
  }

  if (loading || unassignLoading) {
    return <CircularProgress />;
  }

  return (
    <CommonModalWithHeader
      title={t('modal.edit_user')}
      subtitle={t('modal.edit_explain_text')}
      buttonText={t('modal.submit')}
      onClose={() => handlerChangeModal(USER_ACTION_TYPES.ASSIGNED)}
      buttonFunc={formik.handleSubmit}>
      <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
        <WarningModal
          onClose={closeWarningModal}
          handlerProcessedClick={handlerUnassignToken}
          description={t('modal.unassign_token_description')}
        />
      </CommonModal>
      <S.Form>
        <Input
          label={t('auth.full_name')}
          id="fullName"
          name="fullName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.fullName || user?.name}
          onBlur={formik.handleBlur}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          helperText={formik.touched.fullName && formik.errors.fullName}
        />

        <Input
          label={t('auth.email_address')}
          id="email"
          name="email"
          type="email"
          disabled={true}
          value={formik.values.email || user?.user?.email}
        />
      </S.Form>

      {groupOptions && (
          <S.SelectStyle options={groupOptions} onChange={handlerSelectGroup} value={selectedGroup} label={t('auth.group')}/>
      )}

      <S.TokenBlock>
        {t('modal.token')}
        <S.TokenAddButton
          text={t('modal.assign_token')}
          onClick={() => handleUnassignedToken(USER_ACTION_TYPES.ASSIGNING_TOKEN, user)}
        />
      </S.TokenBlock>

      <S.TokenList>
        {user?.evcoId && tokenData?.tokens?.length
          ? tokenData?.tokens?.map((item) => (
              <S.TokenItemBlock key={item.id}>
                <div>
                  <S.TokenItemTitle>{item.print}</S.TokenItemTitle>
                  <S.TokenItemSubTitle>
                    <p>{item.id}</p>
                    <p>{item.deviceType}</p>
                  </S.TokenItemSubTitle>
                </div>

                <S.TokenItemIcons>
                  <span onClick={() => handlerEditToken(USER_ACTION_TYPES.EDIT_TOKEN, item, user)}>
                    <CopyIcon />
                  </span>
                  <span onClick={() => handlerOpenWarningModal(item)}>
                    <CancelIcon />
                  </span>
                </S.TokenItemIcons>
              </S.TokenItemBlock>
            ))
          : null}
      </S.TokenList>
    </CommonModalWithHeader>
  );
};

export default EditUser;
