
export const CHART_TYPES = {
  DATE: 'date',
  INFO: 'info',
  USER: 'user'
};

export const TAB_TYPES = {
  LIST: 'list',
  CARD: 'card'
};

export const ALERT_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success'
};

export const USER_ACTION_TYPES = {
  ASSIGNED: 'assigned',
  ADD_USER: 'add_user',
  EDIT: 'edit',
  EDIT_GROUP: 'edit_group',
  EDIT_TOKEN: 'edit_token',
  ADD_TOKEN: 'add_token',
  CREATE_GROUP: 'create_group',
  ASSIGNING_TOKEN: 'assigning_token'
};

export const CHARGING_STATUS_TYPES = {
  READY: 'READY',
  PREPARING: 'PREPARING',
  CHARGING: 'CHARGING',
  OFFLINE: 'OFFLINE',
  ERROR: 'ERROR',
  UNAVAILABLE: 'UNAVAILABLE',
  NO_CONNECTION: 'NO_CONNECTION',
  SUSPENDED: 'SUSPENDED',
  SUSPENDED_ENV: 'SuspendedEV'
};

export const ROLES_TYPES = {
  SITE_ADMIN: 'SITE_ADMIN',
  USER: 'USER'
};
