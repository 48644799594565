import React from 'react';

import * as S from './styled';
import Sidebar from '../../../components/UI/Sidebar';
import { isToken } from '../../../utils/localStorage';
import { Navigate } from 'react-router-dom';
import { ContextProvider } from '../../../context';

const PrivateRoute = ({ component }) => {
  const isAuth = isToken();

  if (!isAuth) {
    return <Navigate to={'/login'} />;
  }

  return (
    <ContextProvider>
        <S.Layout>
          <Sidebar />
          <S.Content>{component}</S.Content>
        </S.Layout>
    </ContextProvider>
  );
};

export default PrivateRoute;
