export const validateTariffValues = (e) => {
  e.target.value = e.target.value
    .replace(/[^0-9,]/g, '') // Entfernt alle Zeichen außer Zahlen und Komma
    .replace(/,+/g, ',') // Erlaubt nur ein Komma
    .replace(/^,|,,/g, '')  // Verhindert, dass ein Komma am Anfang steht;
};

export const validateWholeNumbers = (e) => {
  // Entfernt alle Zeichen, die keine Zahlen (0-9) sind
  e.target.value = e.target.value.replace(/[^0-9]/g, '');
};

export const validateVerificationID = (value) => {
  const letters = /^[0-9a-fA-F]+$/;
  const splitBySeparator = value?.split(':');
  const valueWithoutSeparator = value?.split(':')?.join('');

  if (!letters.test(valueWithoutSeparator)) {
    return false;
  }

  if (splitBySeparator?.length > 1) {
    if (valueWithoutSeparator?.length !== 8 && valueWithoutSeparator?.length !== 14) {
      return false;
    }

    const arrOfValue = value?.split('');

    for (let i = 0; i < arrOfValue?.length - 2; i++) {
      if (arrOfValue[2] !== ':') return false;

      if (arrOfValue[i] === ':' && i < 2) return false;

      if (arrOfValue[i] === ':' && arrOfValue[i + 2] !== ':') return false;

      return true;
    }
  } else if (
    splitBySeparator?.length === 1 &&
    (valueWithoutSeparator?.length === 8 || valueWithoutSeparator?.length === 14)
  ) {
    return true;
  }
  return false;
};
