import {gql} from "@apollo/client";


export const GET_GROUP_BY_ID = gql`
  query getGroupBySiteID($groupFilter: GroupFilter) {
    groups(groupFilter: $groupFilter) {
      group_id
      group_name
      billing_method
      contracts {
        evcoId
        name
        user {
        id
        email
        fullName
       }
      }
    }
  }
`

export const GET_GROUPS = gql`
    query Groups {
      groups {
        group_name
        group_id
        deleted
        billing_method
      }
    }
`

export const UPDATE_GROUP = gql`
  mutation UpdateGroups($group: UpdateGroupInput!, $filter: GroupFilter!) {
    updateGroups(group: $group, filter: $filter)
  }
`

export const ASSIGN_GROUP_TO_CONNECTOR = gql`
   mutation AssignConnectorToGroup($assignFilter: AssignConnectorToGroupFilter!) {
    assignConnectorToGroup(assignFilter: $assignFilter)
   }
`

export const REMOVE_GROUP_FROM_CONNECTOR = gql`
   mutation RemoveConnectorFromGroup($assignFilter: AssignConnectorToGroupFilter!) {
    removeConnectorFromGroup(assignFilter: $assignFilter)
   }
`

export const DELETE_GROUP = gql`
    mutation DeleteGroup($group: GroupFilter!) {
     deleteGroup(group: $group)
    }
`

export const ASSIGN_CONTRACT_TO_GROUP = gql`
    mutation AssignContractToGroup($assignFilter: AssignToGroupFilter!) {
        assignContractToGroup(assignFilter: $assignFilter)
    }
`

export const INVITE_USER = gql`
    mutation InviteUser($inviteUser: CreateContractInput!) {
        inviteUser(inviteUser: $inviteUser)
    }
`

export const CREATE_GROUP = gql`
    mutation CreateGroup($group: CreateGroupInput!) {
        createGroup(group: $group) {
        group_id
        group_name
        billing_method
        site {
            id
          }
        }
    }    
`