import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import * as S from './styled';
import ContentHeader from '../../components/UI/ContentHeader';
import { FirstCalendar, SecondCalendar } from '../../components/Icons';
import ChartData from '../../components/UI/Chart';
import TableGrid from '../../components/UI/TableGrid';
import { ALL_CHARGING_SESSIONS } from '../../apollo/chargingHistory';
import { GET_ALL_SITES } from '../../apollo/settings';
import { CHART_TYPES } from '../../enums';
import { useContextInfo } from '../../context';
import {roundTo1Decimal} from "../../utils";

const ChargingHistory = () => {
  const { t } = useTranslation();
  const { userInfo, selectedSite, setSelectedSite } = useContextInfo();
  const { isSiteAdmin } = userInfo;

  const { data: allSites } = useQuery(GET_ALL_SITES);

  const [dateStart, setDateStart] = useState(
    moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );
  const [dateEnd, setDateEnd] = useState(
    moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectValue, setSelectValue] = useState(selectedSite);
  const [sortType, setSortType] = useState('date');
  const [totalKWHAmount, setTotalKWHAmount] = useState(0);

  const { data, loading, error, refetch } = useQuery(ALL_CHARGING_SESSIONS, {
    skip: selectValue === '',
    variables: {
      chargingSessionFilter: {
        from: dateStart,
        to: dateEnd,
        siteId: selectValue
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.charging_sessions?.length){
      let totalAmount = 0;

      for(let entry in data.charging_sessions){
        let amount = data.charging_sessions[entry].amount || 0
        totalAmount += roundTo1Decimal(amount/1000)
      }
      console.log(data)
      console.log(totalAmount)
      setTotalKWHAmount(roundTo1Decimal(totalAmount))
    } else {
      setTotalKWHAmount(0.0)
    }
  },[data])

  useEffect(() => {
    if (allSites?.sites?.length) {
      const options = allSites?.sites?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          name: item.name
        };
      });

      setSelectOptions(options);
      if(!selectedSite){setSelectValue(options[0]?.value);}
    }
  }, [allSites]);

  const handlerSetStartDate = (value) => {
    setDateStart(value.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
  };

  const handlerSetEndDate = (value) => {
    setDateEnd(value.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
  };

  const handlerSort = (value) => {
    setSortType(value);
  };

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const handlerSelect = async (e) => {
    console.log("refetching")
    await refetch();
    const currentValue = selectOptions?.find((item) => item.id === e.target.value);
    setSelectValue(currentValue?.value);
    setSelectedSite(currentValue?.value);
  };

  return (
    <S.Container
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}>
      <ContentHeader showTab={false} />
      <S.Info>{t('chargingHistory.charging_history')}</S.Info>
      <S.SelectSiteStyle options={selectOptions} onChange={handlerSelect} value={selectValue} />
      <S.CalendarBlock>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['year', 'month', 'day']}
            label={t('chargingHistory.select_date')}
            value={dateStart}
            inputFormat={'YYYY-MMM-DD'}
            onChange={handlerSetStartDate}
            renderInput={(params) => (
              <S.TimePickerWrapper>
                <S.CustomTextField {...params} helperText={null} label={null} />
                <FirstCalendar />
              </S.TimePickerWrapper>
            )}
          />
          <DatePicker
            views={['year', 'month', 'day']}
            label={t('chargingHistory.select_date')}
            value={dateEnd}
            inputFormat={'YYYY-MMM-DD'}
            onChange={handlerSetEndDate}
            renderInput={(params) => (
              <S.TimePickerWrapper>
                <S.CustomTextField {...params} helperText={null} label={null} />
                <SecondCalendar />
              </S.TimePickerWrapper>
            )}
          />
        </LocalizationProvider>
      </S.CalendarBlock>

      <S.SortBlock>
        <S.SortBlockItem
          isSiteAdmin={isSiteAdmin}
          active={sortType === CHART_TYPES.DATE}
          onClick={() => handlerSort(CHART_TYPES.DATE)}>
          {t('chargingHistory.sort_by_date')}
        </S.SortBlockItem>
        <S.SortBlockItem
          isSiteAdmin={isSiteAdmin}
          active={sortType === CHART_TYPES.INFO}
          onClick={() => handlerSort(CHART_TYPES.INFO)}>
          {t('chargingHistory.group_by_wallbox')}
        </S.SortBlockItem>
        {isSiteAdmin && (
          <S.SortBlockItem
            isSiteAdmin={isSiteAdmin}
            active={sortType === CHART_TYPES.USER}
            onClick={() => handlerSort(CHART_TYPES.USER)}>
            {t('chargingHistory.group_by_user')}
          </S.SortBlockItem>
        )}
      </S.SortBlock>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <S.ChartWrapper>
            <ChartData
              data={data}
              type={sortType}
              diapasonOfDate={{
                from: dateStart,
                to: dateEnd
              }}
            />
          </S.ChartWrapper>
          <S.TableWrapper>
            <S.TableWrapperTitle>{t('chargingHistory.charging_history')}</S.TableWrapperTitle>
            <div>
              <S.TotalAmount>
                {t('chargingHistory.total_amount')}: {new Intl.NumberFormat(navigator.language, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1
                }).format(totalKWHAmount)} kWh
              </S.TotalAmount>
            </div>
            {data && <TableGrid value={data?.charging_sessions} type={sortType} />}
          </S.TableWrapper>
        </>
      )}
    </S.Container>
  );
};

export default ChargingHistory;
