import React, { useEffect, useState } from 'react';
import { Chart, Series, Legend, ValueAxis, Tooltip } from 'devextreme-react/chart';

import { Button } from 'devextreme-react/button';

import { light } from '../../../styles/themes';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CHART_TYPES } from '../../../enums';
import {roundTo1Decimal} from "../../../utils";
import {charge_amount_formating} from "../../../utils/custom_formating";
import {sortByKey} from "../../../utils/sorts";

const colors = ['#6babac', light.main.primary];

const ChartData = ({ data, type, diapasonOfDate = false }) => {
  const { t } = useTranslation();

  const [isFirstLevel, setIsFirstLevel] = useState(true);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    switch (type) {
      case CHART_TYPES.DATE:
        sortByDates();
        break;
      case CHART_TYPES.INFO:
        sortByConnectors();
        break;
      case CHART_TYPES.USER:
        sortByUser();
        break;
      default:
        break;
    }
  }, [data, type]);

  const customizePoint = () => {
    return {
      color: colors[Number(isFirstLevel)],
      hoverStyle: !isFirstLevel
        ? {
            hatching: 'none'
          }
        : {}
    };
  };

  const sortByMonth = () => {
    if (data?.charging_sessions?.length) {
      const currentData = [
        { arg: t('month.january'), val: 0, parentID: '' },
        { arg: t('month.february'), val: 0, parentID: '' },
        { arg: t('month.march'), val: 0, parentID: '' },
        { arg: t('month.april'), val: 0, parentID: '' },
        { arg: t('month.may'), val: 0, parentID: '' },
        { arg: t('month.june'), val: 0, parentID: '' },
        { arg: t('month.july'), val: 0, parentID: '' },
        { arg: t('month.august'), val: 0, parentID: '' },
        { arg: t('month.september'), val: 0, parentID: '' },
        { arg: t('month.october'), val: 0, parentID: '' },
        { arg: t('month.november'), val: 0, parentID: '' },
        { arg: t('month.december'), val: 0, parentID: '' }
      ];

      data?.charging_sessions?.forEach((item) => {
        if (item.startTime) {
          currentData?.map((v) => {
            if (v?.arg === moment(item.startTime).format('MMMM')) {
              v.val += charge_amount_formating(item.amount);
              return v;
            }

            return v;
          });
        }
      });

      setChartData([...currentData]);
    }
  };

  const sortByConnectors = () => {
    if (data?.charging_sessions?.length) {
      let connectorsChartData = [];
      data?.charging_sessions?.forEach((item) => {
        const isConnector = connectorsChartData?.find((c) => c?.arg === item?.connector_name);

        if (!isConnector) {
          return connectorsChartData.push({
            arg: item?.connector_name,
            val: +charge_amount_formating(item.amount)
          });
        }

        connectorsChartData?.filter((i) =>
          i?.arg === item?.connector_name ? i.val += +charge_amount_formating(item.amount) : null
        );
      });

      connectorsChartData = roundAndSort(connectorsChartData, true)
      setChartData([...connectorsChartData]);
    }
  };

  const sortByUser = () => {
    if (data?.charging_sessions?.length) {
      let connectorsChartData = [];
      data?.charging_sessions?.forEach((item) => {
        const isUser = connectorsChartData?.find((user) => user?.arg === item?.contract_name);
        if (!isUser) {
          return connectorsChartData.push({
            arg: item?.contract_name,
            val: +charge_amount_formating(item.amount)
          });
        }

        connectorsChartData?.filter((i) =>
          i?.arg === item?.contract_name ? i.val += +charge_amount_formating(item.amount) : null
        );
      });

      connectorsChartData = roundAndSort(connectorsChartData, true)
      setChartData([...connectorsChartData]);
    }
  };

  const sortByDates = () => {
    if (data?.charging_sessions?.length === 0) {
      return false;
    }

    let dates = [];
    let startDate = moment(diapasonOfDate?.from);
    const endDate = moment(diapasonOfDate?.to);
    while (startDate.isSameOrBefore(endDate)) {
      dates.push({
        date: startDate.format('YYYY-MM-DD'),
        arg: new Intl.DateTimeFormat(navigator.language).format(startDate.toDate()), // Lokales Datumsformat
        val: 0
      });
      startDate.add(1, 'days');
    }

    data?.charging_sessions?.map((item) => {
      return dates?.filter((data) => {
        if (moment(item?.startTime)?.format('YYYY-MM-DD') === data?.date) {
          data.val += charge_amount_formating(item.amount);
        }
        return data;
      });
    });

    dates = roundAndSort(dates, false)
    setChartData([...dates]);
  };

  const sortByDays = (month) => {
    if (data?.charging_sessions?.length) {
      const currentData = [];
      data?.charging_sessions?.forEach((item) => {
        if (moment(item?.startTime)?.format('MMMM') === month) {
          const dayInMonth = moment(item?.startTime, 'YYYY-MM').daysInMonth();

          for (let i = 1; i <= dayInMonth; i++) {
            const obj = {
              arg: `${i} ${moment(item?.startTime)?.format('MMM')}`,
              val: 0,
              parentID: moment(item?.startTime)?.format('MMMM')
            };

            currentData.push(obj);
          }

          if (currentData?.length) {
            currentData?.map((v) => {
              if (v?.arg === moment(item.startTime).format('DD MMM')) {
                v.val += charge_amount_formating(item.amount);
                return v;
              }

              return v;
            });
          }
        }
      });

      setChartData([...currentData]);
    }
  };

  function roundAndSort(array, sort=false){
    // auf eine decimal stelle runden
    array.map((data) => {
      data.val = roundTo1Decimal(data.val)
    })

    // sotier array
    if(sort){
      array = sortByKey(array,"arg")
    }

    return array;

  }

  const onPointClick = (e) => {
    if (isFirstLevel) {
      setIsFirstLevel(false);
      sortByDays(e.target.argument);
    }
  };

  const onButtonClick = () => {
    if (!isFirstLevel) {
      setIsFirstLevel(true);
      sortByMonth();
    }
  };

  const customizeTooltip = (e) => {
    // Dynamische Tooltip-Daten basierend auf dem Typ
    let additionalInfo = '';
    
    if (type === CHART_TYPES.DATE) {
      const formattedDate = new Intl.DateTimeFormat(navigator.language).format(new Date(e.argument));
      additionalInfo = `${t('chargingHistory.date')}: ${formattedDate} <br/>`;
    } else if (type === CHART_TYPES.USER) {
      additionalInfo = `${t('chargingHistory.user')}: ${e.argument} <br/>`;
    } else if (type === CHART_TYPES.INFO) {
      additionalInfo = `${t('chargingHistory.chargepoint')}: ${e.argument} <br/>`;
    }
  
    // Tooltip-Text generieren
    return {
      text: `${additionalInfo}${t('chargingHistory.amount')}: ${Math.abs(e.valueText)} kWh`
    };
  };

  return (
    <>
      <Chart
        id="chart"
        customizePoint={customizePoint}
        className={isFirstLevel ? 'pointer-on-bars' : ''}
        dataSource={chartData}>
        <Series type="bar" />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
        <ValueAxis showZero={true} />
        <Legend visible={false} />
      </Chart>
      <Button
        className="button-container"
        text="Back"
        icon="chevronleft"
        visible={!isFirstLevel}
        onClick={onButtonClick}
      />
    </>
  );
};

export default ChartData;
