import { gql } from '@apollo/client';

export const CREATE_TOKEN = gql`
  mutation createToken($token: CreateTokenInput!) {
    createToken(token: $token) {
      print
      deviceType
      id
      contract {
        evcoId
      }
    }
  }
`;

export const GET_TOKENS = gql`
  query getTokens($tokenFilter: TokenFilter) {
    tokens(tokenFilter: $tokenFilter) {
      key
      id
      deviceType
      print
    }
  }
`;

export const UPDATE_TOKEN = gql`
  mutation updateToken($token: UpdateTokenInput!, $filter: TokenFilter!) {
    updateTokens(token: $token, filter: $filter)
  }
`;

export const DELETE_TOKEN = gql`
  mutation deleteToken($tokenId: String!, $siteId: Int) {
    deleteToken(tokenId: $tokenId, siteId: $siteId) 
  }
`;

export const UNASSIGN_TOKEN = gql`
  mutation UnassignToken($filter: TokenFilter!) {
    unassignToken(filter: $filter) {
      id
    }
  }
`;


