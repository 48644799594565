import styled from 'styled-components';
import TextField from '@mui/material/TextField';

import { subTitleText, textLarge, textMedium } from '../../styles/texts';
import SelectSite from '../../components/UI/selectSite';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
  width: 100%;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 0 16px;
  }
`;

const Info = styled.p`
  margin: 4px 0 14px;
  ${subTitleText};
  font-weight: bold;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    margin-top: 46px;
  }
`;

const SelectSiteStyle = styled(SelectSite)`
  max-width: 338px;
`;

const InfoSubtitle = styled.p`
  ${subTitleText};
  font-weight: 500;
  line-height: 24px;
`;

const TimePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

const CustomTextField = styled(TextField)`
  margin-right: 16px;
  padding: 8px 10px;
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 16px;
  position: relative;

  fieldset {
    border: none;
  }

  input {
    padding: 0;
    color: ${({ theme }) => theme.color.text.primary.lightGray};
  }

  div {
    max-width: 110px;
    padding: 0;
    margin: 0;
  }

  button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;

    &:active,
    &:hover {
      background: transparent;
    }

    svg {
      display: none;
    }
  }
`;

const CalendarBlock = styled.div`
  display: flex;
  margin-top: 22px;
  margin-bottom: 24px;
`;

const SortBlock = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const SortBlockItem = styled.span`
  position: relative;
  display: block;
  padding: 11px 32px;
  margin-right: 16px;
  ${textMedium};
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid rgba(133, 78, 249, 0.1);
  transition: 0.3s all;

  &:hover {
    background: ${({ theme }) => theme.color.background.lightWhite};
  }

  ${({ active, theme }) =>
    active &&
    `
    background: ${theme.color.background.lightWhite};
  `}

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 11px 25px;
    width: 49%;
    margin-right: 0;

    ${({ isSiteAdmin }) =>
      isSiteAdmin &&
      `
      &:last-child {
        margin-top: 10px;
      }
    `}}
  }
`;

const ChartWrapper = styled.div`
  margin-bottom: 38px;
  padding: 50px 50px 18px 20px;
  max-width: 888px;
  width: 100%;
  border-radius: 30px;
  background: ${({ theme }) => theme.color.background.secondary};
`;

const TableWrapper = styled.div`
  position: relative;
  padding: 20px 24px;
  max-width: 888px;
  width: 100%;
  border-radius: 30px;
  background: ${({ theme }) => theme.color.background.secondary};
`;

const TotalAmount = styled.p`
  ${textMedium};
  margin-bottom: 20px;
`

const TableWrapperTitle = styled.p`
  ${textLarge};
  margin-bottom: 10px;
`;

export {
  Container,
  Info,
  TimePickerWrapper,
  CustomTextField,
  CalendarBlock,
  SortBlock,
  SelectSiteStyle,
  SortBlockItem,
  ChartWrapper,
  TableWrapper,
  TableWrapperTitle,
  InfoSubtitle,
  TotalAmount
};
