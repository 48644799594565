import { useTranslation } from 'react-i18next';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import { ALERT_TYPES, USER_ACTION_TYPES } from '../../../../../enums';
import CommonModal from '../../../CommonModal';
import WarningModal from '../../../WarningModal';
import * as S from '../AssigningToken/styled';
import { RemoveIcon } from '../../../../Icons';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_TOKEN, DELETE_TOKEN, GET_TOKENS } from '../../../../../apollo/token';
import AlertMessage from '../../../AlertMessage';


const AssigningToken = (
  {
    user,
    handlerChangeModal,
    handlerEditToken,
    handlerCreateToken,
    handlerActions,
    siteValue,
  }
) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Assigning Token");
    tokenRefetch().then(
      () => {
        console.log("refetch Tokens")
      }
    )
  }, []);

  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [deletedToken, setDeletedToken] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(ALERT_TYPES.ERROR);
  const [alertText, setAlertText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const closeSnackbar = () => {
    setOpenSnackbar(false);
    // reset Alert severity
    setAlertSeverity(ALERT_TYPES.ERROR);
  }

  // creating a new token with the same id and site as a token without contract will assign the token to the contract
  const [addToken, { error: addTokenError }] = useMutation(CREATE_TOKEN, {
    refetchQueries: [
      {
        query: GET_TOKENS,
        variables: {
          tokenFilter: {
            siteId: siteValue?.id,
            showUnassignToken: true
          }
        }
      }
    ],
    refetchPolicy: 'network-only',
  });

  const [deleteToken, { error: deleteContractError, loading: deleteLoading }] = useMutation(
    DELETE_TOKEN,
    {
      refetchQueries: [
        {
          query: GET_TOKENS,
          variables: {
            tokenFilter: {
              siteId: siteValue?.id,
              showUnassignToken: true
            }
          }
        }
      ]
    }
  );

  const {
    data: tokenData,
    loading,
    error: tokenError,
    refetch: tokenRefetch,
  } = useQuery(GET_TOKENS, {
    variables: {
      tokenFilter: {
        siteId: siteValue?.id,
        showUnassignToken: true
      }
    }
  });

  const handlerOpenWarningModal = (token) => {
    setIsOpenWarningModal(true);
    setDeletedToken(token);
  };

  const closeWarningModal = () => {
    setIsOpenWarningModal(false);
    setDeletedToken(null);
  };

  const handlerDeletedToken = async () => {
    const variables = {
      tokenId: deletedToken?.id,
      siteId: siteValue?.id,
    };

    await deleteToken({ variables });
    closeWarningModal();

    handlerActions(t('modal.token_delete_success'));
  };

  const handlerAssigningToken = async (value) => {
    const variables = {
      token: {
        deviceType: value?.deviceType,
        id: value?.id,
        print: value?.name,
        evcoId: user?.evcoId,
        siteId: siteValue?.id,
      }
    }
    try {
      await addToken({ variables });
      if(addTokenError) {
        setAlertText(addTokenError.message);
      } else {
        setAlertSeverity(ALERT_TYPES.SUCCESS);
        setAlertText(t('modal.assign_token_description'))
      }
    } catch (error) {
      setAlertText(error.message);
    }
    setOpenSnackbar(true);
  }

  if (loading || deleteLoading) {
    return <CircularProgress />;
  }

  return (
    <CommonModalWithHeader
      title={t('modal.assign_token')}
      subtitle={t('modal.assign_token_explain_text')}
      buttonText={t('modal.add_new')}
      buttonFunc={() => handlerCreateToken(USER_ACTION_TYPES.ADD_TOKEN, user)}
      onClose={() => handlerChangeModal(USER_ACTION_TYPES.EDIT, user)}
      assigned={true}
      displayCreateSymbolOnButton={false}
      >
      <AlertMessage severity={alertSeverity} open={openSnackbar} onClose={closeSnackbar} errorText={alertText} />
      <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
        <WarningModal
          onClose={closeWarningModal}
          handlerProcessedClick={handlerDeletedToken}
          description={t('modal.delete_token_description')}
        />
      </CommonModal>

      <S.TokenList>
        {user?.evcoId && tokenData?.tokens?.length
          ? tokenData?.tokens?.map((item) => (
            <S.TokenItemBlock key={item.id}>
              <div>
                <S.TokenItemTitle>{item.print}</S.TokenItemTitle>
                <S.TokenItemSubTitle>
                  <p>{item.id}</p>
                  <p>{item.deviceType}</p>
                </S.TokenItemSubTitle>
              </div>

              <S.TokenItemIcons>
                  <span onClick={ () => handlerAssigningToken(item) /*() => handlerEditToken(USER_ACTION_TYPES.EDIT_TOKEN, item, user)*/}>
                    <S.TokenAddButton
                      text={
                        <>
                          {t("modal.assign_token_to_user")}
                        </>
                      }
                    />
                  </span>
                <span onClick={() => handlerOpenWarningModal(item)}>
                    <RemoveIcon />
                  </span>
              </S.TokenItemIcons>
            </S.TokenItemBlock>
          ))
          : null}
      </S.TokenList>
    </CommonModalWithHeader>
  )

}
export default AssigningToken;