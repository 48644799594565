import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import { api } from '../../services';
import AuthFormBlock from '../../components/UI/AuthFormBlock';
import AlertMessage from '../../components/UI/AlertMessage';

const ActivateInvitationUser = () => {
  const { t } = useTranslation(); // useTranslation-Hook
  const { id } = useParams();

  const [isLoader, setIsLoader] = useState(false);
  const [activateInitialData, setActivateInitialData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isLinkInvalid, setIsLinkInvalid] = useState(false); // Neuer Zustand für ungültigen Link

  const closeSnackbar = () => {
    setOpenSnackbar(false);
    setErrorText('');
  };

  useEffect(() => {
    if (id) {
      fetchActivateUserData();
    }
  }, [id]);

  const fetchActivateUserData = async () => {
    setIsLoader(true);
    try {
      const response = await api.invitation.getUserInvitation(id);
      setActivateInitialData(response?.data);
    } catch (e) {
      // Prüfen, ob der Link ungültig ist
      if (e?.response?.status === 404 || e?.response?.data?.message === 'Invitation not found') {
        setIsLinkInvalid(true);
      } else {
        setOpenSnackbar(true);
        setErrorText(e?.response?.data?.message || t('common.error'));
      }
    } finally {
      setIsLoader(false);
    }
  };

  if (isLoader) {
    return (
        <CircularProgress />      
    );
  }

  if (isLinkInvalid) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h2>{t('activation.link_not_available')}</h2>
        <p>{t('activation.account_already_activated')}</p>
        <p>
          {t('activation.login_prompt')}{' '}
          <Link to="/login">{t('activation.login_page')}</Link>.{' '}
          {t('activation.reset_password')}
        </p>
      </div>
    );
  }

  return (
    <>
      <AlertMessage open={openSnackbar} onClose={closeSnackbar} errorText={errorText} />
      {Object.keys(activateInitialData)?.length && (
        <AuthFormBlock isActivate={true} isLogin={false} activateData={activateInitialData} />
      )}
    </>
  );
};

export default ActivateInvitationUser;
