import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import LogoutIcon from '@mui/icons-material/Logout';

import * as S from './styled';
import { HistoryIcon, HomeIcon, SettingsIcon } from '../../../Icons';
import NavigationItem from './NavigationItem';
import routes from '../../../../router/pages/routes';
import { removeRefreshToken, removeToken } from '../../../../utils/localStorage';
import { useContextInfo } from '../../../../context';

const Navigation = ({ onClose }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { userInfo } = useContextInfo();
  const { isSiteAdmin } = userInfo;
  const client = useApolloClient();

  const handlerLogout = () => {
    removeToken();
    removeRefreshToken();
    client.clearStore();
  };

  return (
    <S.MainNavigation>
      <S.MainNavigationTop>
        <NavigationItem
          text={t('sidebar.home')}
          Icon={<HomeIcon active={location.pathname === routes.HOME} />}
          active={location.pathname === routes.HOME}
          route={routes.HOME}
          onClick={onClose}
        />
        <NavigationItem
          text={t('sidebar.charging_history')}
          Icon={<HistoryIcon active={location.pathname === routes.CHARGING_HISTORY} />}
          active={location.pathname === routes.CHARGING_HISTORY}
          route={routes.CHARGING_HISTORY}
          onClick={onClose}
        />
        {/*<NavigationItem*/}
        {/*  text={t('sidebar.support')}*/}
        {/*  Icon={<SupportIcon active={location.pathname === routes.SUPPORT} />}*/}
        {/*  active={location.pathname === routes.SUPPORT}*/}
        {/*  route={routes.SUPPORT}*/}
        {/*/>*/}
        {isSiteAdmin && (
          <NavigationItem
            text={t('sidebar.settings')}
            Icon={<SettingsIcon active={location.pathname === routes.SETTINGS} />}
            active={location.pathname === routes.SETTINGS}
            route={routes.SETTINGS}
            onClick={onClose}
          />
        )}
      </S.MainNavigationTop>

      <S.MainNavigationBottom>
        <NavigationItem
          text={t('sidebar.logout')}
          Icon={<LogoutIcon style={{ fill: '#1B1B1B' }} />}
          active={location.pathname === routes.LOGOUT}
          route={routes.LOGIN}
          onClick={handlerLogout}
        />
      </S.MainNavigationBottom>
    </S.MainNavigation>
  );
};

export default Navigation;
