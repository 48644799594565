import React from 'react';
import { CHARGING_STATUS_TYPES } from '../../../enums';
import * as S from './styled'

const OccupiedByBlock = (
  {
    isSiteAdmin,
    state,
    newestChargingSession,
  }
) => {

  return (
    isSiteAdmin && (
        state === CHARGING_STATUS_TYPES.SUSPENDED_ENV ||
          state === CHARGING_STATUS_TYPES.SUSPENDED ||
          state === CHARGING_STATUS_TYPES.CHARGING ||
          state === CHARGING_STATUS_TYPES.PREPARING
      )
      && (
      <div>
        <S.UserItem key={newestChargingSession?.contract_name}>{newestChargingSession?.contract_name}</S.UserItem>
      </div>
    )
  )
}
export default OccupiedByBlock