import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {useContextInfo} from "../../../../context";
import * as S from "../../styled";
import {createStripeCustomer} from "../../../../services/api/payment";

const PaymentSettings = ({handlerActions}) => {
    const { t } = useTranslation();
    const { userInfo: userInfoData } = useContextInfo();
    const { info: userInfo } = userInfoData;

    useEffect(() => {
        if(userInfo){
            console.log(userInfo)
            console.log("stripeCustomerId == "+userInfo?.stripeCustomerId)
            if(userInfo?.stripeCustomerId == null){
                console.log("no stripe account -> create one now ")
                const data = {email: userInfo.email, name: userInfo.fullName}
                createStripeCustomer(data).then(
                    res => console.log(res)
                )
            }
        }
    },[])

    const handleSubmit = async () => {
        {/* eslint-disable-next-line no-undef */}
        window.location = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL
    }

    return (<div>
            <S.Title>{t("settings.payment_configuration")}</S.Title>
            <S.PaymentInfo>
                <S.PaymentInfo>
                {t("settings.payment_configuration_info")}                    
                    <S.SubTitle>{t("settings.payment_configuration_steps_title")}</S.SubTitle>
                    <S.stepByStepList>
                        <S.stepElement>{t("settings.payment_configuration_step_1")}</S.stepElement>
                        <S.stepElement>{t("settings.payment_configuration_step_2")} ‘{userInfo?.email}’ </S.stepElement>
                        <S.stepElement>{t("settings.payment_configuration_step_3")}</S.stepElement>
                        <S.stepElement>{t("settings.payment_configuration_step_4")}</S.stepElement>
                    </S.stepByStepList>
                </S.PaymentInfo>
                <S.LinkButton text={t("settings.open_external_customer_portal")} onClick={handleSubmit}/>
            </S.PaymentInfo>
        </div>
    );

};

export default PaymentSettings;
